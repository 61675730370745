<template>
  <v-card class="ma-4">
    <v-toolbar :color="selectedItems.length ? 'grey darken-4' : '#335D6E'" dark
               elevation="1">

      <v-toolbar-title> {{
          selectedItems.length ? `Selected (${selectedItems.length})  rows` : title
        }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-select
          v-model="statusOrder"
          :items="statusOrders"
          filled
          hide-details
          item-text="name"
          item-value="id"
          label="Show Orders"
          return-object
          single-line
      >
      </v-select>
      <v-spacer></v-spacer>

      <v-text-field
          v-if="!selectedItems.length"
          v-model="search"
          append-icon="mdi-magnify"
          clearable
          filled
          hide-details
          label="Search about something"
          single-line
      ></v-text-field>

      <v-btn
          v-if="selectedItems.length"
          color="grey darken-2"
          dark
          @click.stop="restoreItems"
      >Restore
      </v-btn>

      <v-dialog v-if="!selectedItems.length" v-model="dialogAddItem" fullscreen
                hide-overlay
                transition="dialog-bottom-transition">

        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="mx-11"
              color="red"
              dark
              v-bind="attrs"
              v-on="on"
          >New
          </v-btn>
        </template>

        <v-card v-if="dialogAddItem">
          <v-toolbar
              color="blue-grey"
              dark
          >
            <v-btn
                dark
                icon
                @click="dialogAddItem = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-menu v-if="(this.tab >2 && this.tab <6)" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip class="mt-4" color="grey"
                          dark flat
                          label v-bind="attrs" v-on="on"
                  >Change Status To
                  </v-chip>


                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title
                        v-if="((this.tab===3 && item?.preQuestionStatus===0) ||(this.tab===4 && item?.postQuestionStatus===0) ||(this.tab===5 && item?.imageEvidenceStatus===0)   )"
                    ><span style="cursor: pointer" @click="changeStatus(1)">Accept</span></v-list-item-title>
                    <v-list-item-title
                        v-if="((this.tab===3 && (item?.preQuestionStatus===0 || item?.preQuestionStatus===1)) || (this.tab===4 && (item?.postQuestionStatus===0 || item?.postQuestionStatus===1))|| (this.tab===5 && (item?.imageEvidenceStatus===0 || item?.imageEvidenceStatus===1)))"
                    ><span style="cursor: pointer" @click="changeStatus(2,false)">Cancel</span></v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn
                  :disabled="(!valid || buttonLoading)"
                  :loading="buttonLoading"
                  dark
                  text
                  @click="validate"
              >Save
              </v-btn>
            </v-toolbar-items>
            <template v-slot:extension>
              <v-tabs
                  v-model="tab"
                  center-active
                  centered

              >
                <v-tabs-slider color="yellow"></v-tabs-slider>

                <v-tab
                    v-for="(tabItem,index) in tabItems"
                    :key="tabItem"
                    @click="tab=tabItem"
                >

                  <v-badge v-if="index>2 && index<6 && item!=null" :color="checkDotColor(index)"
                           :dot="true" floating>
                    {{ tabItem }}
                  </v-badge>

                  <div v-else>
                    {{ tabItem }}
                  </div>


                </v-tab>
              </v-tabs>
            </template>
          </v-toolbar>


          <v-alert
              v-if="Object.keys(errors).length>0"
              dense
              text
              type="error"
          >
            <h4 class="subtitle">Correct the following errors:</h4>
            <ul>
              <li
                  v-for="(error, index) in errors"
                  :key="index"
              >{{ error.message || error }}
              </li>
            </ul>
          </v-alert>


          <v-card-text>
            <v-container>
              <v-tabs-items v-model="tab">
                <v-tab-item
                    v-for="tabItem in tabItems"
                    :key="tabItem"
                >
                  <v-form
                      v-if="Number(tab)===0"
                      ref="form"
                      v-model="valid"
                      class="mt-4"
                      lazy-validation>

                    <v-row>

                      <v-col cols="12" lg="4" md="4" sm="6">
                        <v-select
                            v-model="user"
                            :items="users"
                            :readonly="orderType===1 || isFormTitleEdit"
                            :rules="[v => !!v || 'Please select customer']"
                            hide-details
                            item-text="name"
                            item-value="id"
                            label="Customer Name"
                            outlined
                            required
                            return-object
                        ></v-select>
                      </v-col>

                      <v-col cols="12" lg="4" md="4" sm="6">
                        <v-text-field v-model="email" :readonly="true"
                                      label="Email"
                                      outlined></v-text-field>

                      </v-col>

                      <v-col cols="12" lg="4" md="4" sm="6">
                        <v-text-field v-model="phone" :readonly="true"
                                      label="Phone number"
                                      outlined></v-text-field>

                      </v-col>

                      <v-col cols="12" lg="4" md="4" sm="6">
                        <v-text-field v-model="paymentType" :readonly="true"
                                      label="Payment type"
                                      outlined required></v-text-field>

                      </v-col>

                      <v-col cols="12" lg="4" md="4" sm="6">
                        <v-text-field v-model="planName" :readonly="true"
                                      label="Plan Name"
                                      outlined required></v-text-field>

                      </v-col>

                      <v-col cols="12" lg="4" md="4" sm="6">
                        <v-select
                            v-model="itemStatusOrder"
                            :items="statusOrders.filter(e=>e.id!==-1)"
                            :rules="[v => !!v || 'Please select from list']"
                            hide-details
                            item-text="name"
                            item-value="id"
                            label="Order Status"
                            outlined
                            required
                            return-object
                        ></v-select>
                      </v-col>

                      <v-col cols="12">
                        <v-textarea v-if="itemStatusOrder!=null && itemStatusOrder.id===2" v-model="cancelReason"
                                    :counter="1000"
                                    :disabled="buttonLoading"
                                    :rules="noteRules " clearable label="Cancel Reason"
                                    no-resize outlined
                                    rows="5"
                        ></v-textarea>
                      </v-col>


                      <v-col cols="12" lg="4" md="4" sm="6">
                        <v-dialog
                            ref="dialog"
                            v-model="dialogCalendar"
                            :retain-focus="false"
                            :return-value.sync="installDate"
                            :show-current="false"
                            persistent
                            width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="installDate"
                                :rules="[v => !!v || 'Install date is required']"
                                label="Install date"
                                outlined
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                              v-model="installDate"
                              :min="new Date().toISOString().substring(0, 10)"
                              scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary"
                                text
                                @click="dialogCalendar = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                                color="blue-grey"
                                text
                                @click="$refs.dialog[0].save(installDate)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </v-col>

                      <v-col cols="12" lg="8" md="8" sm="6">
                        <v-autocomplete
                            v-model="suggestionBillingAddress"
                            :items="itemsBillingAddress"
                            :loading="isLoadingBillingAddress"
                            :search-input.sync="billingSearch"
                            append-icon="mdi-magnify"
                            clearable
                            hide-selected
                            hideNoData
                            item-text="suggestion"
                            item-value="id"
                            label="Billing address Auto Select"
                            no-filter
                            outlined
                            placeholder="Start typing to search for billing address"
                            return-object
                            single-line
                            @click:append="billingSearchPostcode"
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="12" lg="3" md="3" sm="6">
                        <v-text-field v-model="city" :rules="[v => !!v || 'Please insert city']"
                                      label="Billing city"
                                      outlined required></v-text-field>
                      </v-col>

                      <v-col cols="12" lg="3" md="3" sm="6">
                        <v-text-field v-model="postcode" :rules="postcodeRules" label="Billing Postcode"
                                      outlined required></v-text-field>
                      </v-col>

                      <v-col cols="12" lg="3" md="3" sm="6">
                        <v-text-field v-model="addressLine1" :rules="addressLine1Rules"
                                      label="Billing Address Line 1"
                                      outlined required></v-text-field>
                      </v-col>

                      <v-col cols="12" lg="3" md="3" sm="6">
                        <v-text-field v-model="addressLine2" :rules="addressLine2Rules"
                                      label="Billing Address Line 2"
                                      outlined></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <v-checkbox
                            v-model="isSameAddress"
                            color="red"
                            label="Is install address same billing address ?"
                        ></v-checkbox>
                      </v-col>

                      <v-col v-if="!isSameAddress" cols="12">
                        <v-autocomplete
                            v-model="suggestionInstallAddress"
                            :items="itemsInstallAddress"
                            :loading="isLoadingInstallAddress"

                            :search-input.sync="installSearch"
                            append-icon="mdi-magnify"
                            clearable
                            hide-selected
                            hideNoData
                            item-text="suggestion"
                            item-value="id"
                            label="Install address Auto Select"
                            no-filter
                            outlined
                            placeholder="Start typing to search for install address"
                            return-object
                            single-line
                            @click:append="installSearchPostcode"
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="12" lg="3" md="3" sm="6">
                        <v-text-field v-model="installCity" :rules="[v => !!v || 'Please insert city']"
                                      label="City"
                                      outlined required></v-text-field>
                      </v-col>

                      <v-col cols="12" lg="3" md="3" sm="6">
                        <v-text-field v-model="installPostcode" :rules="postcodeRules" label="Postcode"
                                      outlined required></v-text-field>
                      </v-col>

                      <v-col cols="12" lg="3" md="3" sm="6">
                        <v-text-field v-model="installLine1" :rules="addressLine1Rules" label="Address Line 1"
                                      outlined required></v-text-field>
                      </v-col>

                      <v-col cols="12" lg="3" md="3" sm="6">
                        <v-text-field v-model="installLine2" :rules="addressLine2Rules" label="Address Line 2"
                                      outlined></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <v-textarea v-model="note" :counter="1000" :disabled="buttonLoading"
                                    :rules="noteRules " clearable filled
                                    label="Notes" no-resize
                                    rows="5"
                        ></v-textarea>
                      </v-col>

                      <v-col class="d-flex" cols="12">
                        <v-col cols="12" lg="6" md="6" sm="12">
                          <v-select
                              v-model="product"
                              :items="products"
                              :rules="[v => !!v || 'Product name is required']"
                              hide-details
                              item-text="name"
                              item-value="id"
                              label="Product Name"
                              outlined
                              required
                              return-object
                              @change="changeProduct(product)"
                          ></v-select>

                        </v-col>
                        <v-col cols="12" lg="2" md="2" sm="12">


                          <v-text-field :rules="[v => !!v || 'Amount is required']" label="Amount" max="1" min="1"
                                        outlined
                                        readonly
                                        type="number" value="1"

                          ></v-text-field>

                        </v-col>
                        <v-col cols="12" lg="2" md="2" sm="12">
                          <v-text-field v-model="productPrice" label="Price" min="0" outlined
                                        readonly type="number"
                          ></v-text-field>

                        </v-col>
                        <v-col cols="12" lg="2" md="2" sm="12">
                          <v-text-field v-model="productTotal" :rules="[v => !!v || 'Total is required']"
                                        label="Total"
                                        min="0"
                                        outlined type="number"
                                        @input="calculateTotalPrices()"
                          ></v-text-field>
                        </v-col>
                      </v-col>


                      <v-col cols="12">
                        <div v-for="(cartAddon,index) in cartAddons" :key="index"
                             class="d-flex">

                          <v-col cols="12" lg="6" md="6" sm="12">
                            <v-select
                                v-model="cartAddon.addon"
                                :items="addons"

                                :rules="[v => !!v || 'Addon name is required']"
                                hide-details
                                item-text="name"
                                item-value="id"
                                label="Addon Name"
                                outlined
                                required
                                return-object
                                @change="changeAddon(index, cartAddon)"
                            ></v-select>

                          </v-col>

                          <v-col cols="12" lg="1" md="3" sm="6">

                            <v-text-field v-model="cartAddon.number" :disabled="addons.length<1 || cartAddon.addon.id<1"
                                          :name="cartAddon.number"
                                          :rules="[v => !!v || 'Amount is required']"
                                          label="Amount" min="0" outlined
                                          type="number" @input="changeAddon(index, cartAddon)"
                            ></v-text-field>

                          </v-col>

                          <v-col cols="12" lg="2" md="3" sm="6">
                            <v-text-field v-model="cartAddon.addon.price"
                                          :disabled="addons.length<1 || cartAddon.addon.id<1"
                                          :name="cartAddon.addon.price" label="Price" min="0"
                                          outlined readonly
                                          type="number"
                            ></v-text-field>

                          </v-col>
                          <v-col cols="12" lg="2" md="3" sm="6">
                            <v-text-field v-model="cartAddon.total" :disabled="addons.length<1 || cartAddon.addon.id<1 "
                                          :name="cartAddon.total"
                                          label="Total" min="0" outlined
                                          type="number"
                                          @input="changeTotal(index, cartAddon)"
                            ></v-text-field>
                          </v-col>

                          <div
                              v-if="((index<1 && cartAddons.length<1) || ((index===cartAddons.length-1) && addons.length>0 &&   cartAddon.addon.id>0) ) && orderType!==1"
                              class="d-inline-flex  align-center ml-2 mb-8 ">
                            <v-btn
                                color="primary"
                                elevation="0"
                                fab
                                x-small
                                @click="addToCartAddon(index+1)"
                            >
                              <v-icon color="white">
                                mdi-plus
                              </v-icon>
                            </v-btn>
                          </div>
                          <div v-if="cartAddons.length>1 && orderType!==1" class="d-inline-flex align-center ml-2 mb-8">
                            <v-btn
                                color="red"
                                elevation="0"
                                fab
                                x-small
                                @click="removeFromCartAddon(index)"
                            >
                              <v-icon color="white">
                                mdi-minus
                              </v-icon>
                            </v-btn>
                          </div>


                        </div>

                      </v-col>

                      <v-col class="d-flex flex-wrap justify-end" cols="12">

                        <v-col cols="12" lg="3" md="3" sm="6">
                          <v-text-field v-model="installPrice" label="Install Price" min="0"
                                        outlined type="number"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="3" md="3" sm="6">
                          <v-text-field v-model="postcodePrice" label="postcode Price" min="0"
                                        outlined type="number"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="3" md="3" sm="6">
                          <v-text-field v-model="totalTax" label="Tax Percent" min="0"
                                        outlined type="number"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="3" md="3" sm="6">
                          <v-text-field v-model="totalPrices" label="Total Prices" min="0" outlined
                                        readonly type="number"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="3" md="3" sm="6">
                          <v-text-field v-model="couponSlug" disabled label="coupon Slug" outlined
                                        readonly type="text"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="3" md="3" sm="6">
                          <v-text-field v-model="couponPrice" disabled label="coupon Price" min="0" outlined
                                        readonly type="number"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="3" md="3" sm="6">
                          <v-text-field v-model="totalDiscount" label="Discount Price" min="0"
                                        outlined type="number"
                          ></v-text-field>
                        </v-col>


                        <v-col cols="12" lg="3" md="3" sm="6">
                          <v-text-field v-model="totalFinalPrice" label="Final Price" min="0" outlined
                                        readonly type="number"
                          ></v-text-field>
                        </v-col>

                      </v-col>


                    </v-row>
                  </v-form>

                  <v-form
                      v-show="Number(tab)===1"
                      ref="form"
                      v-model="valid"
                      class="mt-4"
                      lazy-validation>
                    <v-row>
                      <v-col v-for="(parent,index) in questions.filter(c => c.parentID === 0 && c.isActive===true)"
                             :key="index" cols="12"
                             lg="4"
                             md="6"
                             sm="12">


                        <div class="black--text font-weight-bold mb-2">{{ parent.name }}</div>

                        <v-select
                            v-model="questionAnswers[parent.id]"
                            :items="questions.filter(c => c.parentID === parent.id && c.isActive===true)"
                            hide-details
                            item-text="name"
                            item-value="id"
                            label="Select from menu"
                            outlined
                            return-object
                        ></v-select>


                      </v-col>


                    </v-row>
                  </v-form>

                  <v-form
                      v-if="Number(tab)===2"
                      ref="form"
                      v-model="valid"
                      class="mt-4"
                      lazy-validation>
                    <v-row>

                      <v-col cols="12" lg="3" md="6" sm="12">
                        <v-select
                            v-model="engineer"
                            :items="engineers"
                            :rules="[v => !!v || 'Please select engineer']"
                            hide-details
                            item-text="name"
                            item-value="id"
                            label="Engineer Name"
                            outlined
                            required
                            return-object
                        ></v-select>
                      </v-col>

                      <v-col cols="12" lg="3" md="3" sm="6">
                        <v-text-field v-model="engPrice" label="Price for eng." min="0" outlined
                                      type="number"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" lg="3" md="3" sm="6">
                        <v-checkbox
                            v-model="engIsVerticalFlue"
                            color="red"
                            label="Is vertical flue ?"
                        ></v-checkbox>
                      </v-col>

                      <v-col cols="12" lg="6" md="12" sm="12">
                        <v-textarea v-model="engNote" :counter="1000"
                                    :disabled="buttonLoading"
                                    :rules="noteRules " clearable label="Engineers's Note"
                                    outlined
                                    rows="1"
                        ></v-textarea>
                      </v-col>

                      <v-col v-if="engineers" cols="12" lg="6" md="4" sm="2">
                        <v-btn
                            :disabled=" buttonLoadingAssign "
                            :loading="buttonLoadingAssign"
                            class="mt-2"
                            color="green"
                            dark
                            filled
                            @click="clickAssign"
                        >Assign Order
                        </v-btn>
                      </v-col>

                      <v-col cols="12">


                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                            <tr>
                              <th>#</th>
                              <th>Engineer name</th>
                              <th>Assign Date</th>
                              <th>Price</th>
                              <th>isVertical Flue</th>
                              <th>Status</th>
                              <th>Note</th>
                              <th>Last update</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                v-for="(item,index) in dessertsEngineers"
                                :key="index"
                            >
                              <td>
                                <v-chip>{{ index + 1 }}</v-chip>
                              </td>
                              <td>{{ getUser(item.userID).name }}</td>
                              <td>{{ item.createdAt.substr(0, 10) }}</td>
                              <td>{{ item.price }}</td>
                              <td>{{ item.isVerticalFlue ? "Yes" : "No" }}</td>
                              <td>
                                <v-chip :color="statusColor(item.status)" dark label>{{
                                    checkStatus(item.status)
                                  }}
                                </v-chip>
                              </td>
                              <td>{{ item.engNote }}</td>
                              <td>{{ item.updatedAt.substr(0, 10) }}</td>
                            </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                  </v-form>

                  <v-form
                      v-if="Number(tab)===3"
                      ref="form"
                      v-model="valid"
                      class="mt-4"
                      lazy-validation
                      readonly>
                    <v-alert v-if="item?.preQuestionStatus===2"
                             border="bottom"
                             color="pink darken-1"
                             dark
                    >
                      {{ item.preQuestionCancelReason }}
                    </v-alert>
                    <v-row>


                      <v-col v-for="(installAnswer,index) in installAnswers.filter((e)=>e.InstallQuestion.isPreInstall)"
                             :key="index"
                             cols="12" lg="4"
                             md="6"
                             sm="12">

                        <v-radio-group
                            v-if="installAnswer.InstallQuestion.type===0 ||installAnswer.InstallQuestion.type===1"
                            v-model="installAnswer.multiChoiceAnswer"
                            inline="true" mandatory
                        >
                          <template v-slot:label>
                            <div class="black--text font-weight-bold mb-2">{{
                                installAnswer.InstallQuestion.name
                              }}
                            </div>
                          </template>
                          <div class="d-inline-flex align-start ml-2 ">
                            <v-radio
                                v-if="installAnswer.InstallQuestion.type===0 ||installAnswer.InstallQuestion.type===1"
                                :value="0"
                                label="No"></v-radio>
                            <v-radio
                                v-if="installAnswer.InstallQuestion.type===0 ||installAnswer.InstallQuestion.type===1"
                                :value="1" class="mx-6"
                                label="Yes"></v-radio>
                            <v-radio v-if="installAnswer.InstallQuestion.type===1" :value="2"
                                     label="Na"></v-radio>

                          </div>
                        </v-radio-group>

                        <v-textarea v-else v-model="installAnswer.shortAnswer"
                                    :label="installAnswer.InstallQuestion.name"
                                    auto-grow
                                    class="black--text font-weight-bold mb-2"></v-textarea>


                      </v-col>

                    </v-row>
                  </v-form>

                  <v-form
                      v-if="Number(tab)===4"
                      ref="form"
                      v-model="valid"
                      class="mt-4"
                      lazy-validation
                      readonly>
                    <v-alert v-if="item?.postQuestionStatus===2"
                             border="bottom"
                             color="pink darken-1"
                             dark
                    >
                      {{ item.postQuestionCancelReason }}
                    </v-alert>
                    <v-row>

                      <v-col
                          v-for="(installAnswer,index) in installAnswers.filter((e)=>!e.InstallQuestion.isPreInstall)"
                          :key="index"
                          cols="12" lg="4"
                          md="6"
                          sm="12">

                        <v-radio-group
                            v-if="installAnswer.InstallQuestion.type===0 ||installAnswer.InstallQuestion.type===1"
                            v-model="installAnswer.multiChoiceAnswer"
                            inline="true" mandatory
                        >
                          <template v-slot:label>
                            <div class="black--text font-weight-bold mb-2">{{
                                installAnswer.InstallQuestion.name
                              }}
                            </div>
                          </template>
                          <div class="d-inline-flex align-start ml-2 ">
                            <v-radio
                                v-if="installAnswer.InstallQuestion.type===0 ||installAnswer.InstallQuestion.type===1"
                                :value="0"
                                label="No"></v-radio>
                            <v-radio
                                v-if="installAnswer.InstallQuestion.type===0 ||installAnswer.InstallQuestion.type===1"
                                :value="1" class="mx-6"
                                label="Yes"></v-radio>
                            <v-radio v-if="installAnswer.InstallQuestion.type===1" :value="2"
                                     label="Na"></v-radio>

                          </div>
                        </v-radio-group>

                        <v-textarea v-else v-model="installAnswer.shortAnswer"
                                    :label="installAnswer.InstallQuestion.name"
                                    auto-grow
                                    class="black--text font-weight-bold mb-2"></v-textarea>

                      </v-col>

                    </v-row>
                  </v-form>

                  <v-form
                      v-if="Number(tab)===5"
                      ref="form"
                      v-model="valid"
                      lazy-validation
                      readonly
                  >
                    <v-alert v-if="item?.imageEvidenceStatus===2"
                             border="bottom"
                             color="pink darken-1"
                             dark
                    >
                      {{ item.imageEvidenceCancelReason }}
                    </v-alert>
                    <v-row>

                      <v-col v-for="(imageType,index) in imageTypes.filter((e)=>e.parentID===1)" :key="index" cols="12"
                             lg="4"
                             md="6" sm="12">
                        <v-card
                            class="ma-4">
                          <v-card-actions>
                            <v-list-item>
                              <v-list-item-avatar color="grey lighten-3">

                                {{ checkImageOrder(imageType).length }}


                              </v-list-item-avatar>

                              <v-list-item-content>
                                <v-list-item-title class="black--text font-weight-bold mb-2">{{ imageType.name }}
                                </v-list-item-title>
                              </v-list-item-content>

                            </v-list-item>


                          </v-card-actions>

                          <v-expand-transition>
                            <div v-show="true">
                              <v-divider></v-divider>
                              <v-card-text>
                                <div v-for="(imageOrder,indexOrder)  in checkImageOrder(imageType)"
                                     :key="indexOrder"
                                     class="d-inline-flex">
                                  <div>
                                    <v-avatar class="mr-2 ">
                                      <img :src="previewImage(imageOrder.image)"
                                           @click="previewImage(imageOrder.image,true)">
                                    </v-avatar>
                                    <v-btn
                                        :onclick="downloadImage(imageOrder.image)"
                                        class="d-block ml-1 mt-2"
                                        color="primary"
                                        icon
                                    >
                                      <v-icon>mdi-download-circle-outline</v-icon>
                                    </v-btn>
                                  </div>

                                </div>
                              </v-card-text>
                            </div>
                          </v-expand-transition>
                        </v-card>
                      </v-col>

                    </v-row>
                  </v-form>

                  <v-form
                      v-if="Number(tab)===6"
                      ref="form"
                      v-model="valid"
                      lazy-validation
                      readonly
                  >
                    <v-alert
                        border="bottom"
                        color="green darken-1"
                        dark
                    >
                      you can upload the images to admin through the link
                      <span class="yellow--text text--darken-3  " style="cursor: pointer" @click="copyURL ">( click here to past ) </span>


                    </v-alert>
                    <v-row>

                      <v-col v-for="(imageType,index) in imageTypes.filter((e)=>e.parentID===0)" :key="index" cols="12"
                             lg="4"
                             md="6" sm="12">
                        <v-card
                            class="ma-4">
                          <v-card-actions>
                            <v-list-item>
                              <v-list-item-avatar color="grey lighten-3">

                                {{ checkImageOrder(imageType).length }}


                              </v-list-item-avatar>

                              <v-list-item-content>
                                <v-list-item-title class="black--text font-weight-bold mb-2">{{ imageType.name }}
                                </v-list-item-title>
                              </v-list-item-content>

                            </v-list-item>


                          </v-card-actions>

                          <v-expand-transition>
                            <div v-show="true">
                              <v-divider></v-divider>
                              <v-card-text>
                                <div v-for="(imageOrder,indexOrder)  in checkImageOrder(imageType)"
                                     :key="indexOrder"
                                     class="d-inline-flex">
                                  <div>
                                    <v-avatar class="mr-2 ">
                                      <img :src="previewImage(imageOrder.image)"
                                           @click="previewImage(imageOrder.image,true)">
                                    </v-avatar>
                                    <v-btn
                                        :onclick="downloadImage(imageOrder.image)"
                                        class="d-block ml-1 mt-2"
                                        color="primary"
                                        icon
                                    >
                                      <v-icon>mdi-progress-download</v-icon>
                                    </v-btn>
                                  </div>

                                </div>
                              </v-card-text>
                            </div>
                          </v-expand-transition>
                        </v-card>
                      </v-col>

                    </v-row>
                  </v-form>

                  <v-form
                      v-if="Number(tab)===7"
                      ref="form"
                      v-model="valid"
                      class="mt-4"
                      lazy-validation>
                    <v-row>

                      <v-col cols="12" lg="3" md="6" sm="12">
                        <v-file-input
                            v-model="documentImage"
                            :rules="documentRules"
                            accept="image/png, image/jpeg, image/bmp, application/pdf"
                            label="Upload Document/Image"
                            outlined
                            placeholder="File Document"
                            prepend-icon="mdi-attachment"
                        ></v-file-input>
                      </v-col>

                      <v-col cols="12" lg="3" md="6" sm="12">
                        <v-text-field v-model="documentTitle" :counter="150" clearable label="Title document"
                                      outlined></v-text-field>
                      </v-col>

                      <v-col cols="12" lg="6" md="4" sm="2">
                        <v-btn
                            :disabled=" buttonLoadingAssign "
                            :loading="buttonLoadingAssign"
                            class="mt-2"
                            color="green"
                            dark
                            filled
                            @click="clickUploadDocument"
                        >Upload Document
                        </v-btn>
                      </v-col>

                      <v-col cols="12">


                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                            <tr>
                              <th>#</th>
                              <th>Title</th>
                              <th>Type</th>
                              <th>Created Date</th>
                              <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                v-for="(item,index) in imageOrders.filter((e)=>(e.imageTypeID===8 || e.imageTypeID===getImageTypeID()  ))"
                                :key="index"
                            >
                              <td>
                                <v-chip>{{ index + 1 }}</v-chip>
                              </td>
                              <td>{{ item.title }}</td>
                              <td>{{ checkDocumentType(item.image) }}</td>
                              <td>{{ convertTimeToDate(item.createdAt) }}</td>
                              <td>
                                <v-btn
                                    v-if="checkDocumentType(item.image)!=='PDF'"
                                    class="ma-2"
                                    color="blue"
                                    dark
                                    outlined
                                    @click="previewImage(item.image,true)"
                                >
                                  Show
                                  <v-icon
                                      dark
                                      right
                                  >
                                    mdi-checkbox-marked-circle
                                  </v-icon>
                                </v-btn>
                                <a v-if="checkDocumentType(item.image)==='PDF'"
                                   :href="appendPdfUrl(item.image,item.imageTypeID)" target="_blank">
                                  <v-btn
                                      class="ma-2"
                                      color="blue"
                                      dark
                                      outlined
                                  >
                                    Show
                                    <v-icon
                                        dark
                                        right
                                    >
                                      mdi-checkbox-marked-circle
                                    </v-icon>
                                  </v-btn>


                                </a>
                                <v-btn
                                    color="red"
                                    dark
                                    outlined
                                    @click="showDeleteDialog(item)"
                                >

                                  Delete
                                  <v-icon right>
                                    mdi-delete
                                  </v-icon>
                                </v-btn>
                              </td>

                            </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                  </v-form>

                  <v-form
                      v-if="Number(tab)===8"
                      ref="form"
                      v-model="valid"
                      class="mt-4"
                      lazy-validation>
                    <v-row>


                      <v-col v-if="isNewNote" cols="12" lg="4" md="4" sm="6">
                        <v-text-field v-model="noteAdminTitle"
                                      label="Note Title"
                                      outlined required></v-text-field>

                      </v-col>

                      <v-col v-if="isNewNote" cols="12">
                        <v-textarea v-model="noteAdminBody" :counter="1000" :disabled="buttonLoading"
                                    :rules="noteRules " clearable label="Note Body"
                                    no-resize outlined
                                    rows="5"
                        ></v-textarea>
                      </v-col>

                      <v-col cols="12" lg="6" md="4" sm="2">
                        <v-btn
                            :disabled=" buttonLoadingAssign "
                            :loading="buttonLoadingAssign"
                            class="mt-2"
                            color="green"
                            dark
                            filled
                            @click="clickSaveNote"
                        >{{ !isNewNote ? 'New Note' : 'Save Note' }}
                        </v-btn>
                      </v-col>

                      <v-col cols="12">


                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                            <tr>
                              <th>#</th>
                              <th>Title</th>
                              <th>Created Date</th>
                              <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                v-for="(item,index) in noteOrders"
                                :key="item.id"
                            >
                              <td>
                                <v-chip>{{ index + 1 }}</v-chip>
                              </td>
                              <td>{{ item.title }}</td>
                              <td>{{ convertTimeToDate(item.createdAt) }}</td>
                              <td>
                                <v-btn
                                    class="ma-2"
                                    color="orange"
                                    dark
                                    outlined
                                    @click="showNote(item)"
                                >
                                  Show
                                  <v-icon
                                      dark
                                      right
                                  >
                                    mdi-checkbox-marked-circle
                                  </v-icon>
                                </v-btn>
                                <v-btn
                                    class="ma-2"
                                    color="blue"
                                    dark
                                    outlined
                                    @click="editNote(item)"
                                >
                                  Edit
                                  <v-icon
                                      dark
                                      right
                                  >
                                    mdi-checkbox-marked-circle
                                  </v-icon>
                                </v-btn>
                                <v-btn
                                    color="red"
                                    dark
                                    outlined
                                    @click="showDeleteDialog(item)"
                                >

                                  Delete
                                  <v-icon right>
                                    mdi-delete
                                  </v-icon>
                                </v-btn>
                              </td>

                            </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                  </v-form>

                  <v-form
                      v-if="Number(tab)===9"
                      ref="form"
                      v-model="valid"
                      class="mt-4"
                      lazy-validation>
                    <v-row>

                      <v-col v-if="isNewMail" cols="12" lg="3" md="3" sm="6">
                        <v-select
                            v-model="mailTemplate"
                            :items="mailTemplates"

                            :rules="[v => !!v || 'Template mail is required']"
                            hide-details
                            item-text="name"
                            item-value="id"
                            label="Template Mail"
                            outlined
                            required
                            return-object
                            @change="changeMailTemplate(mailTemplate)"
                        ></v-select>
                      </v-col>
                      <v-col v-if="isNewMail" cols="12" lg="4" md="4" sm="6">
                        <v-text-field v-model="mailTitle"
                                      label="Mail Title"
                                      outlined required></v-text-field>

                      </v-col>

                      <v-col v-if="isNewMail" cols="12">
                        <v-tiptap v-model="mailContent" :toolbar="tipTapToolbar">
                          <template #mybutton="{ editor }">
                            <div class="ml-n1">
                              <v-select
                                  v-model="tag"
                                  :items="tags"
                                  dense
                                  hide-details="auto"
                                  item-text="name"
                                  item-value="id"
                                  return-object
                                  style="width: 104px"
                                  v-on:change="changeTag( tag,editor)"
                              />
                            </div>
                          </template>
                        </v-tiptap>
                      </v-col>

                      <v-col cols="12" lg="6" md="4" sm="2">
                        <v-btn
                            :disabled=" buttonLoadingAssign "
                            :loading="buttonLoadingAssign"
                            class="mt-2"
                            color="green"
                            dark
                            filled
                            @click="clickSaveMail"
                        >{{ !isNewMail ? 'New Mail' : 'Save Mail' }}
                        </v-btn>
                      </v-col>

                      <v-col cols="12">


                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                            <tr>
                              <th>#</th>
                              <th>Title</th>
                              <th>Created Date</th>
                              <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                v-for="(item,index) in orderEmails"
                                :key="item.id"
                            >
                              <td>
                                <v-chip>{{ index + 1 }}</v-chip>
                              </td>
                              <td>{{ item.mailTitle }}</td>
                              <td>{{ convertTimeToDate(item.createdAt, true) }}</td>
                              <td>
                                <v-btn
                                    class="ma-2"
                                    color="orange"
                                    dark
                                    outlined
                                    @click="showMail(item)"
                                >
                                  Show
                                  <v-icon
                                      dark
                                      right
                                  >
                                    mdi-checkbox-marked-circle
                                  </v-icon>
                                </v-btn>
                              </td>

                            </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                  </v-form>

                </v-tab-item>
              </v-tabs-items>
            </v-container>
          </v-card-text>

          <v-dialog v-if="showDialogImage" v-model="showDialogImage" max-width="30%"
                    @keydown.esc="showDialogImage=false">
            <v-card>
              <v-img :src="imageUrl" alt="" class="fill-height" contain/>
            </v-card>
          </v-dialog>

          <v-dialog v-if="showDialogDeleteDoc" v-model="showDialogDeleteDoc"
                    max-width="290"
                    persistent
          >

            <v-card>
              <v-card-title class="text-h5">
                Delete Data
              </v-card-title>
              <v-card-text>Do you want to continue process this operation ?</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="red darken-1"
                    text
                    @click="processDeleteOP"
                >
                  Delete
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="showDialogDeleteDoc = false"
                >
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
              v-if="showDialogCancelReason" v-model="showDialogCancelReason"
              max-width="600px"
              persistent
          >
            <v-card>
              <v-card-title>
                <span class="text-h5">Cancel Reason</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-textarea v-model="postCancelReason" :counter="1000" :disabled="buttonLoading"
                                  :rules="noteRules " clearable label="Cancel Reason"
                                  no-resize outlined
                                  rows="5"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="showDialogCancelReason = false"
                >
                  Close
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="changeStatus(2,true)"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

        </v-card>
      </v-dialog>
      <v-btn
          v-else
          class="mx-11"
          color="grey darken-2"
          dark
          @click.stop="showDialogDelete"
      >Delete
      </v-btn>
      <v-btn
          v-if="selectedItems.length"
          icon
          @click="selectedItems = []"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-menu
          v-else
          :close-on-content-click="false"
          :nudge-width="250"
          offset-x
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              v-bind="attrs"
              v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-action>
                <v-switch
                    v-model="isShowDeleted"
                    color="indigo"
                ></v-switch>
              </v-list-item-action>
              <v-list-item-title class="ml-4">Show deleted data</v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-action>
                <v-switch
                    v-model="isForceDeleted"
                    color="indigo"
                ></v-switch>
              </v-list-item-action>
              <v-list-item-title class="ml-4">Delete data forever</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-toolbar>

    <v-card-text>
      <v-dialog
          v-model="dialogDelete"
          max-width="425"
          persistent
      >
        <v-card>
          <v-card-title>
            Delete group of data ({{ selectedItems.length ? selectedItems.length : 1 }}) rows
          </v-card-title>

          <v-card-text class=" ">
            Are you sure for continue deleting these data ?
          </v-card-text>

          <v-card-actions>
            <v-checkbox
                v-model="isForceDeletedRow"
                :disabled=disableChangeDelete
                :ripple=false
                class="ma-2 red--text "
                color="red"
                hide-details
            >
              <template v-slot:label>
                <div class="red--text body-2">
                  Delete data forever
                </div>
              </template>
            </v-checkbox>

            <v-spacer></v-spacer>

            <v-btn
                :disabled="buttonLoading"
                class="body-2"
                color="primary"
                text
                @click="hideDialogDelete()"
            >
              Reject
            </v-btn>

            <v-btn
                :disabled="buttonLoading"
                class="body-2"
                color="red darken-2"
                text
                @click="destroyItems"
            >
              Process
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
          v-if="!dialogDelete && !dialogAddItem"
          v-model="buttonLoading"
          hide-overlay
          persistent
          width="300"
      >
        <v-card
            color="indigo"
            dark
        >
          <v-card-text>
            <div class="py-4 body-1 white--text text-center">Waiting for process request... please wait</div>

            <v-progress-linear
                class="my-2"
                color="white"
                indeterminate
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-if="showDialogNote"
                v-model="showDialogNote"
                width="750"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="red lighten-2"
              dark
              v-bind="attrs"
              v-on="on"
          >
            Click Me
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5 text--white grey lighten-1 ">
            {{ noteAdminTitle }}
          </v-card-title>

          <v-card-text class="text-h6">
            {{ noteAdminBody }}
          </v-card-text>


          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="showDialogNote = false"
            >
              close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-if="showDialogMail"
                v-model="showDialogMail"
                width="750"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="red lighten-2"
              dark
              v-bind="attrs"
              v-on="on"
          >
            Click Me
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5 text--white grey lighten-1 ">
            {{ mailTitle }}
          </v-card-title>

          <v-card-text class="text-h6">
            <span v-html="mailContent"></span>
          </v-card-text>


          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="showDialogMail = false"
            >
              close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <v-data-table
          v-model="selectedItems"
          :footer-props="{'items-per-page-options': [10, 20, 40, 80, 100]}"
          :headers="headers"
          :item-class="(item)=>item.deletedAt?'deletedRow':null"
          :items="customDesserts"
          :items-per-page="10"
          :loading="loading"
          :options.sync="options"
          :search="search"
          checkbox-color="'blue'"
          item-key='id'
          show-select
      >


        <template v-slot:header.data-table-select="{props,on}">
          <v-simple-checkbox :ripple=false color="primary" v-bind="props" v-on="on"></v-simple-checkbox>
        </template>
        <template v-slot:item.data-table-select="{isSelected,select}">
          <v-simple-checkbox :ripple=false :value="isSelected" color="primary"
                             @input="select($event)"></v-simple-checkbox>
        </template>

        <template v-slot:item.deletedAt="{ item }">
          <v-icon
              v-if="item.deletedAt"
              class="mr-3"
              color="primary"
              small
              @click="restoreItems(item)"
          >mdi-restore
          </v-icon>


          <v-chip
              color="indigo"
              label
              outlined
              small
              @click="clickEditItem(item)"
          >
            <v-icon small>
              mdi-pencil
            </v-icon>
          </v-chip>


          <v-chip
              :color="item.deletedAt ?'orange':'red accent-4'"
              class="ml-3"
              label
              outlined
              small
              @click="showDialogDelete(item)"
          >
            <v-icon small>
              {{ item.deletedAt ? 'mdi-delete-clock' : ' mdi-delete' }}
            </v-icon>
          </v-chip>

        </template>


        <template v-slot:item.isActive="{ item }">
          <v-icon :color="(item.isActive)?'green':'red'">
            mdi-eye
          </v-icon>
        </template>

        <template v-slot:item.userID="{ item }">
          <v-chip>{{ getUser(item.userID).name }}</v-chip>
        </template>

        <template v-slot:item.installDate="{ item }">
          <v-chip dark>{{ item.installDate }}</v-chip>
        </template>

        <template v-slot:item.createdAt="{ item }">
          <v-chip color="primary" dark outlined>{{ getFormattedDate(new Date(item.createdAt)) }}</v-chip>
        </template>

        <template v-slot:item.type="{ item }">
          <v-chip color="primary" dark>{{ checkType(item.type) }}</v-chip>
        </template>

        <template v-slot:item.status="{ item }">
          <v-chip :color="statusColor(item.status)" dark label>{{
              statusOrders.find(e => e.id === item.status).name
            }}
          </v-chip>
        </template>

        <template v-slot:item.AddressOrder.installPostcode="{ item }">
          <v-chip v-if="item.AddressOrder" color="orange" dark>{{ item.AddressOrder?.installPostcode }}</v-chip>
        </template>

        <template v-slot:item.id="{ item }">
          <v-chip color="primary" dark>{{ item.id }}</v-chip>
        </template>

      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  allRoles,
  imageTypeID,
  pathImageUrl,
  pathInvoicePdfUrl,
  pathPdfUrl,
  tagOrderID,
  urlAddons,
  urlAssignEngineers,
  urlCreateAssignEngineer,
  urlCreateEmailOrder,
  urlCreateImageOrder,
  urlCreateNoteOrder,
  urlCreateOrder,
  urlEmailTemplates,
  urlEngineers,
  urlImageOrders,
  urlNoteOrders,
  urlOrderImageType,
  urlOrders,
  urlPostcodeApi,
  urlPostcodeAutoComplete,
  urlPostcodeKey,
  urlProductPrices,
  urlQuestions,
  urlShowEmailOrders,
  urlShowOrder,
  urlUpdateNoteOrder,
  urlUpdateOrder,
  urlUploadImages,
  urlUsersBasedRole,
} from "@/config";
import networks from "@/services/networks";
import moment from 'moment';
import axios from 'axios';

export default {
  data: () => ({
    search: null,
    dialogDelete: false,
    disableChangeDelete: false,
    deleteItemIDs: [],
    selectedItems: [],
    valid: true,
    id: 0,
    item: null,
    user: null,
    users: [],
    email: null,
    phone: null,
    installDate: null,
    dialogCalendar: false,
    postcodeRules: [
      v => !!v || 'postcode is required',
    ],
    addressLine1Rules: [
      v => !!v || 'address line 1 is required',
      v => (v && v.length <= 150) || 'address line 1 must be less than 150 characters',
    ],
    addressLine2Rules: [
      v => (!v || v.length <= 150) || 'address line 2 must be less than 150 characters',
    ],

    errors: [],
    buttonLoader: 'buttonLoading',
    buttonLoading: false,
    dialogAddItem: false,

    title: "New Orders",

    options: null,
    desserts: [],
    loading: true,
    headers: [
      {text: '#', value: 'id', align: 'center'},
      {text: 'Customer', value: 'userID', align: 'center'},
      {text: 'Inst. Date', value: 'installDate', align: 'center'},
      {text: 'Inst. Postcode', value: 'AddressOrder.installPostcode', align: 'center'},
      {text: 'Type', value: 'type', align: 'center'},
      {text: 'Status', value: 'status', align: 'center'},
      {text: 'Order Date', value: 'createdAt', align: 'center'},
      {text: 'Actions', value: 'deletedAt', sortable: false, align: 'right'},
    ],
    isFormTitleEdit: false,
    totalPrices: 0,

    entriesInstallAddress: [],
    isLoadingInstallAddress: false,
    suggestionInstallAddress: null,
    installSearch: null,
    urlSelectedInstallAddress: null,
    selectedInstallAddress: null,

    installLine1: null,
    installLine2: null,
    installCity: null,
    installPostcode: null,
    addressLine1: null,
    addressLine2: null,
    city: null,
    postcode: null,
    couponSlug: null,
    couponPrice: null,
    note: null,
    cancelReason: null,
    noteRules: [
      v => (!v || v.length <= 1000) || 'This Field must be less than 100 characters',
    ],
    isSameAddress: false,
    entriesBillingAddress: [],
    isLoadingBillingAddress: false,
    suggestionBillingAddress: null,
    billingSearch: null,
    urlSelectedBillingAddress: null,
    selectedBillingAddress: null,
    tab: 0,
    tabItems: ['Order Details', 'Questionnaires Details', 'Engineers', 'Pre Install Form', 'Check-List Form', 'Installation Photos', 'Customer Photo', 'Documents', 'Notes', 'Emails'],

    questions: [],
    questionAnswers: [],

    product: null,
    productPrice: 0,
    productTotal: 0,
    products: [],

    totalDiscount: 0,
    totalTax: 0,
    orderType: 0,
    planName: null,
    installPrice: 0,
    postcodePrice: 0,
    deleteRowID: 0,
    type: null,
    cartAddon: {
      addon: {price: 0, id: 0},
      number: 1,
      total: 0,
    },
    cartAddons: [],
    addons: [],

    statusOrder: {id: -1},
    statusOrders: [{id: -1, name: "All"}, {id: 0, name: "Pending"}, {id: 1, name: "Completed"}, {
      id: 2, name: "Canceled"}, {id: 3, name: "In Process"}, {id: 4, name: "Re-Call Order"}, {id: 5, name: "Application Declined"},],
    customDesserts: [],
    itemStatusOrder: null,
    paymentType: null,
    ////// Tab Form 2 index
    engineers: [],
    engineer: null,
    dessertsEngineers: [],
    buttonLoadingAssign: false,
    engNote: null,
    engPrice: null,
    engIsVerticalFlue: true,

    ////// Tab Form 3 index
    installAnswers: [],

    ////// Tab Form 5 index
    imageTypes: [],
    imageOrders: [],
    showDialogImage: false,
    imageUrl: null,
    pathUploadImages: null,

    ////// Tab Form 7 index
    documentTitle: null,
    documentImage: null,
    document: null,
    documentRules: [
      v => !!v || 'Document is required',
      v => !v || !v.length || v[0].size < 2000000 || 'Document size should be less than 2 MB!',
    ],

    ////// Tab Form 8 index
    isNewNote: false,
    noteAdminTitle: null,
    noteAdminBody: null,
    noteAdminID: null,
    isEditNoteMode: false,
    noteOrders: [],
    showDialogNote: false,

    ////// Tab Form 8 index
    isNewMail: false,
    mailTitle: null,
    mailContent: null,
    mailTemplate: null,
    mailTemplates: [],
    orderEmails: [],
    showDialogMail: false,
    tipTapToolbar: ['bold', 'italic', 'underline', 'strike', 'color', 'highlight', 'headings', 'h1', 'h2', 'h3', 'p', 'left', 'center', 'right', 'justify',
      'codeBlock', 'code', 'rule', 'blockquote', 'bulletList', 'orderedList', 'checkbox', 'link', 'image', 'video', 'emoji', 'clear', '#mybutton'],
    tag: null,
    tags: [{id: 1, name: 'order ID', code: tagOrderID}],

    showDialogDeleteDoc: false,
    showDialogCancelReason: false,
    postCancelReason: null,

  }),

  created() {
    this.getUsersFromApi();
    this.getPriceProductsFromApi();
    this.getQuestionsFromApi();
    this.getImageTypesFromApi();
    this.getEmailTemplatesFromApi();
    this.getOrdersFromApi();
    this.cartAddons.push(this.cartAddon);
  },


  computed: {
    formTitle() {
      return this.isFormTitleEdit ? 'Edit order' : 'Add new order'
    },
    itemsInstallAddress() {
      return this.entriesInstallAddress.map(entry => {
        const suggestion = entry.suggestion;
        return Object.assign({}, entry, {suggestion})
      })
    },
    itemsBillingAddress() {
      return this.entriesBillingAddress.map(entry => {
        const suggestion = entry.suggestion;
        return Object.assign({}, entry, {suggestion})
      })
    },
    totalFinalPrice() {
      const tot = this.totalPrices + Number(this.installPrice) + Number(this.postcodePrice) - this.totalDiscount;

      return (this.totalTax > 0) ?
          (tot - (tot * (this.totalTax / 100))) : tot;
    },
    isShowDeleted: {
      get() {
        return this.$store.getters.isShowDeleted;
      },
      set(val) {
        this.$store.state.isShowDeleted = val;
        this.getOrdersFromApi();
        return val;
      }


    },
    isForceDeleted: {
      get() {
        return this.$store.getters.isForceDeleted;
      },
      set(val) {
        this.isForceDeletedRow = val;
        this.$store.state.isForceDeleted = val;
        return val;
      }
    },
    isForceDeletedRow: {
      get() {
        return this.$store.getters.isForceDeleted;
      },
      set(val) {
        this.$store.state.isForceDeleted = val;
        return val;
      }
    },
  },

  watch: {

    dialogAddItem() {
      if (!this.dialogAddItem) {
        this.isFormTitleEdit = false;
        this.clear();
      } else {
        if (this.orderType === 0)
          this.paymentType = 'Manual Payment';
      }
    },

    suggestionInstallAddress() {
      if (!this.suggestionInstallAddress) return null;

      this.urlSelectedInstallAddress = this.suggestionInstallAddress.urls.udprn;
    },

    urlSelectedInstallAddress() {

      if (this.isLoadingInstallAddress) return;

      this.isLoadingInstallAddress = true;

      fetch(urlPostcodeApi + this.urlSelectedInstallAddress + '?' + urlPostcodeKey)
          .then(res => res.json())
          .then(res => {
            this.selectedInstallAddress = res.result;
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isLoadingInstallAddress = false))
    },

    suggestionBillingAddress() {
      if (!this.suggestionBillingAddress) return null;

      this.urlSelectedBillingAddress = this.suggestionBillingAddress.urls.udprn;
    },

    urlSelectedBillingAddress() {

      if (this.isLoadingBillingAddress) return;

      this.isLoadingBillingAddress = true;

      fetch(urlPostcodeApi + this.urlSelectedBillingAddress + '?' + urlPostcodeKey)
          .then(res => res.json())
          .then(res => {
            this.selectedBillingAddress = res.result;
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isLoadingBillingAddress = false))
    },

    selectedInstallAddress() {
      if (this.selectedInstallAddress) {
        this.installLine1 = this.selectedInstallAddress.line_1;
        this.installLine2 = this.selectedInstallAddress.line_2;
        this.installCity = this.selectedInstallAddress.post_town;
        this.installPostcode = this.selectedInstallAddress.postcode;
      }
    },

    selectedBillingAddress() {
      if (this.selectedBillingAddress) {
        this.addressLine1 = this.selectedBillingAddress.line_1;
        this.addressLine2 = this.selectedBillingAddress.line_2;
        this.city = this.selectedBillingAddress.post_town;
        this.postcode = this.selectedBillingAddress.postcode;

      }
    },
    isSameAddress() {
      if (this.isSameAddress) {
        this.installLine1 = this.addressLine1;
        this.installLine2 = this.addressLine2;
        this.installCity = this.city;
        this.installPostcode = this.postcode;
      } else {
        this.installLine1 = null;
        this.installLine2 = null;
        this.installCity = null;
        this.installPostcode = null;
      }
    },
    statusOrder() {
      if (this.statusOrder.id < 0)
        this.customDesserts = this.desserts;
      else
        this.customDesserts = this.desserts.filter(e => e.status === this.statusOrder.id);
    },
    user() {
      this.email = this.user?.email ?? "";
      this.phone = this.user?.username ?? "";
      this.type = this.item?.type ?? 0;
    },

    tab() {
      if (Number(this.tab) === 9) {
        this.isNewMail = false;
        this.getEmailsOrderFromApi();
      }
    }

  },

  methods: {
    getOrdersFromApi() {
      this.loading = true;
      networks.fetchFromWeb(urlOrders + this.isShowDeleted)
          .then(response => {
            this.desserts = response.data;
            this.customDesserts = this.desserts;
            this.loading = false;
          })
          .catch(error => {
            alert(error);
          });
    },

    getUsersFromApi() {
      this.loading = true;
      networks.fetchFromWeb(urlUsersBasedRole + allRoles + "/true")
          .then(response => {
            this.users = response.data;
            this.loading = false;
          })
          .catch(error => {
            alert(error);
          });


    },

    getPriceProductsFromApi() {
      this.loading = true;
      networks.fetchFromWeb(urlProductPrices)
          .then(response => {
            this.products = response.data;
            this.loading = false;
          })
          .catch(error => {
            alert(error);
          });
    },
    getQuestionsFromApi() {
      this.loading = true;
      networks.fetchFromWeb(urlQuestions)
          .then(response => {
            this.questions = response.data.slice().reverse();
            this.loading = false;
          })
          .catch(error => {
            alert(error);
          });
    },

    getImageTypesFromApi() {
      this.loading = true;
      networks.fetchFromWeb(urlOrderImageType)
          .then(response => {
            this.imageTypes = response.data;
            this.loading = false;
          })
          .catch(error => {
            alert(error);
          });
    }, getEmailTemplatesFromApi() {
      this.loading = true;
      networks.fetchFromWeb(urlEmailTemplates)
          .then(response => {
            this.mailTemplates = response.data;
            this.loading = false;
          })
          .catch(error => {
            alert(error);
          });
    },

    getImageTypeForItemFromApi() {
      this.loading = true;
      networks.fetchFromWeb(urlImageOrders + "/" + this.id)
          .then(response => {
            this.imageOrders = response.data;
            this.loading = false;
          })
          .catch(error => {
            alert(error);
          });
    },

    getNoteForOrderFromApi() {
      this.loading = true;
      networks.fetchFromWeb(urlNoteOrders + this.id)
          .then(response => {
            this.noteOrders = response.data;
            this.loading = false;
          })
          .catch(error => {
            alert(error);
          });
    },

    getEmailsOrderFromApi() {
      this.loading = true;
      networks.fetchFromWeb(urlShowEmailOrders + this.id)
          .then(response => {
            this.orderEmails = response.data;
            this.loading = false;
          })
          .catch(error => {
            alert(error);
          });
    },

    getAddonsFromApi() {
      this.loading = true;
      networks.fetchFromWeb(urlAddons + "product/" + this.product?.id)
          .then(response => {
            this.addons = response.data;
            this.loading = false;
          })
          .catch(error => {
            alert(error);
          });
    },

    getEngineersFromApi(item) {
      this.loading = true;
      networks.fetchFromWeb(urlEngineers + item.subPostcode + "/" + item.installDate)
          .then(response => {
            this.engineers = response.data;
            this.loading = false;
          })
          .catch(error => {
            alert(error);
          });
    },
    getAssignEngineersFromApi() {
      this.loading = true;
      networks.fetchFromWeb(urlAssignEngineers + "/" + this.id)
          .then(response => {
            this.dessertsEngineers = [];
            this.dessertsEngineers = response.data;
            this.loading = false;
          })
          .catch(error => {
            alert(error);
          });
    },

    async copyURL() {
      try {
        await navigator.clipboard.writeText(this.pathUploadImages);
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
    },

    addToCartAddon() {
      this.cartAddons.push({
            addon: {price: 0, id: 0},
            number: 1,
            total: 0,
          }
      );
    },

    removeFromCartAddon(index) {
      this.$delete(this.cartAddons, index);
      this.calculateTotalPrices();
    },

    changeAddon(index, cartAddon) {
      this.cartAddons[index] = {
        addon: cartAddon.addon,
        number: cartAddon.number,
        total: (cartAddon.addon.price * cartAddon.number),
      };
      this.calculateTotalPrices();
    },

    calculateTotalPrices() {
      this.totalPrices = 0;
      this.totalPrices = Number(this.totalPrices) + Number(this.productTotal) - Number(this.couponPrice);
      this.cartAddons.map((cartAddon) => {
        this.totalPrices = Number(this.totalPrices) + Number(cartAddon.total);
      });
    },

    changeTotal(index, cartAddon) {
      this.cartAddons[index] = {
        addon: cartAddon.addon,
        number: cartAddon.number,
        total: Number(cartAddon.total),
      };
      this.calculateTotalPrices();
    },

    changeProduct(product) {
      this.productTotal = Number(product.price) - Number(product.discount);
      this.calculateTotalPrices();
      this.getAddonsFromApi();
    },

    changeMailTemplate(temp) {
      this.mailTitle = temp.templateTitle;
      this.mailContent = this.replaceSymbol(this.tags[0].code, this.item.id.toString(), temp.templateContent);
    },

    changeTag(tag, editor) {
      let hashTagWord = '';
      if (tag.code === tagOrderID) {
        hashTagWord = this.item.id;
      }
      editor.commands.insertContent(hashTagWord.toString());
      this.tag = {id: 0};
    },

    replaceSymbol(symbol, word, string) {
      if (string.indexOf(symbol) < 0) {
        return string;
      }

      while (string.indexOf(symbol) > -1) {
        string = string.replace(symbol, word);
      }

      return string;
    },

    getUser(id) {
      let parent = this.users.filter(c => c.id === id);
      if (parent.length > 0)
        return parent[0];
      return {};
    },

    getProductName(productID) {
      let parent = this.products.filter(c => c.id == productID);
      if (parent.length > 0)
        return parent[0].name;
      return "";
    },

    getFormattedDate(date) {
      return moment(date).format("YYYY-MM-DD")
    },

    statusColor(status) {
      switch (status) {
        case 1:
          return "green";
        case 2:
          return "red";
        case 3:
          return "grey";
        case 4:
          return "brown";
        case 5:
          return "purple";
        case 6:
          return "indigo";

        default:
          return "amber";
      }
    },

    checkStatus(status) {
      switch (status) {
        case 1:
          return "Accepted";
        case 2:
          return "Declined";
        case 3:
          return "Assign to eng.";
        case 4:
          return "Canceled Order";
        case 5:
          return "Complete";
        case 6:
          return "Recall";
        default:
          return "Pending";
      }
    },

    checkType(type) {
      if (type === 0)
        return "Manual";
      else
        return "Automatic";
    },

    billingSearchPostcode() {
      if (this.isLoadingBillingAddress) return;

      this.isLoadingBillingAddress = true;

      fetch(urlPostcodeApi + urlPostcodeAutoComplete + this.billingSearch + '&' + urlPostcodeKey)
          .then(res => res.json())
          .then(res => {
            this.entriesBillingAddress = res.result.hits;
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isLoadingBillingAddress = false));
    },

    editNote(item) {
      this.noteAdminTitle = item.title;
      this.noteAdminBody = item.body;
      this.noteAdminID = item.id;
      this.isEditNoteMode = true;
      this.isNewNote = true;
    },

    showNote(item) {
      this.isNewNote = false;
      this.noteAdminTitle = item.title;
      this.noteAdminBody = item.body;
      this.showDialogNote = true;
    },

    showMail(item) {
      this.isNewMail = false;
      this.mailTitle = item.mailTitle;
      this.mailContent = item.mailContent;
      this.showDialogMail = true;
    },


    appendPdfUrl(image, imgTypeID) {
      return ((imgTypeID === imageTypeID) ? pathInvoicePdfUrl : pathPdfUrl) + image;
    },

    convertTimeToDate(timeStamp, isFullDate = false) {
      const date = new Date(timeStamp);
      if (!isFullDate) {
        return date.toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'});
      } else {
        return date.toLocaleDateString('en-US', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        }) + "  " + date.toLocaleTimeString('en-US');
      }
    },

    checkDocumentType(path) {
      return (path.indexOf(".pdf") !== -1) ? "PDF" : "Image";
    },

    installSearchPostcode() {
      if (this.isLoadingInstallAddress) return;

      this.isLoadingInstallAddress = true;

      fetch(urlPostcodeApi + urlPostcodeAutoComplete + this.installSearch + '&' + urlPostcodeKey)
          .then(res => res.json())
          .then(res => {
            this.entriesInstallAddress = res.result.hits;
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isLoadingInstallAddress = false));
    },

    clickAssign() {
      if (this.engineer && this.engPrice > 0) {


        networks.fetchFromWeb(urlCreateAssignEngineer, 1, {
          userID: this.engineer.id,
          orderID: this.id,
          price: this.engPrice,
          isVerticalFlue: this.engIsVerticalFlue,
          note: this.engNote
        })
            .then(response => {

              if (response.status === 200) {
                alert("the data has been added successfully");
                this.clearEngTab();
                this.getAssignEngineersFromApi();
              }


            })
            .catch(error => {
              this.errors = error.response.data.errors || error.response.data.error;
              this.buttonLoadingAssign = false;

            });
      } else {
        alert("Engineer name and price is required");
      }
    },

    clickUploadDocument() {
      if (this.documentImage != null) {

        const fd = new FormData();

        fd.append("gallery", this.documentImage);
        fd.append("docIDS", "8");
        fd.append("orderID", this.id);
        fd.append("docTitles", this.documentTitle);

        networks.fetchFromWeb(urlCreateImageOrder, 1, fd, true)
            .then(response => {

              if (response.status === 200) {
                this.documentImage = null;
                this.documentTitle = null;
                alert("the data has been added successfully");
                this.getImageTypeForItemFromApi();
              }


            })
            .catch(error => {
              this.errors = error.response.data.errors || error.response.data.error;
              this.buttonLoadingAssign = false;

            });
      }
    },

    clickSaveNote() {
      if (!this.isNewNote) {
        this.isNewNote = true;

      } else {
        if (this.noteAdminTitle) {

          let data = {
            title: this.noteAdminTitle,
            body: this.noteAdminBody,
            orderID: this.id,
          };

          if (this.isEditNoteMode) {
            networks.fetchFromWeb(urlUpdateNoteOrder + this.noteAdminID, 2, data)
                .then(response => {
                  if (response.status === 200) {
                    this.isEditNoteMode = false;
                    alert("the data has been saved successfully");
                    this.errors = [];
                    this.noteAdminID = null;
                    this.noteAdminTitle = null;
                    this.noteAdminBody = null;
                    this.isNewNote = false;
                    this.getNoteForOrderFromApi();
                  }
                })
                .catch(error => {
                  this.errors = error.response.data.errors || error.response.data.error;
                  this.buttonLoadingAssign = false;

                });
          } else {
            networks.fetchFromWeb(urlCreateNoteOrder, 1, data)
                .then(response => {

                  if (response.status === 200) {
                    this.isEditNoteMode = false;
                    alert("the data has been saved successfully");
                    this.errors = [];
                    this.noteAdminID = null;
                    this.noteAdminTitle = null;
                    this.noteAdminBody = null;
                    this.isNewNote = false;
                    this.getNoteForOrderFromApi();
                  }


                })
                .catch(error => {
                  this.errors = error.response.data.errors || error.response.data.error;
                  this.buttonLoadingAssign = false;

                });
          }


        } else {
          alert("The title of note is required");
        }
      }

    },

    clickSaveMail() {
      if (!this.isNewMail) {
        this.isNewMail = true;

      } else {
        if (this.mailTitle) {

          let data = {
            mailTitle: this.mailTitle,
            mailContent: this.mailContent,
            orderID: this.id,
            userID: this.item.userID,
            templateID: this.mailTemplate.id,
          };

          networks.fetchFromWeb(urlCreateEmailOrder, 1, data)
              .then(response => {

                if (response.status === 200) {
                  alert("the data has been saved successfully");
                  this.errors = [];
                  this.mailTitle = null;
                  this.mailContent = null;
                  this.mailTemplate = null;
                  this.isNewMail = false;
                  this.getEmailsOrderFromApi();
                }


              })
              .catch(error => {
                this.errors = error.response.data.errors || error.response.data.error;
                this.buttonLoadingAssign = false;

              });

        } else {
          alert("The title of mail is required");
        }
      }

    },

    getImageTypeID() {
      return imageTypeID;
    },
    showDeleteDialog(item) {
      if (this.tab === 7) {
        this.deleteRowID = item.id;
      } else if (this.tab === 8) {
        this.deleteRowID = item.id;
      }
      this.showDialogDeleteDoc = true;
    },

    processDeleteOP() {
      let url = null;
      if (this.tab === 7) {
        url = urlImageOrders;
      } else if (this.tab === 8) {
        url = urlNoteOrders;
      }
      this.showDialogDeleteDoc = false;

      networks.fetchFromWeb(url + this.deleteRowID, 3)
          .then(response => {
            if (response.status === 200) {
              alert("Data has been deleted");
              if (this.tab === 7) {
                this.getImageTypeForItemFromApi();
              } else if (this.tab === 8) {
                this.getNoteForOrderFromApi();
              }
            }
          })
          .catch(() => {
            alert("Failed for process delete data");
          });


    },

    checkDotColor(tab) {
      if ((tab === 3 && (this.item?.preQuestionStatus === 1)) || (tab === 4 && (this.item?.postQuestionStatus === 1)) || (tab === 5 && (this.item?.imageEvidenceStatus === 1))) {
        return 'green';
      } else if ((tab === 3 && (this.item?.preQuestionStatus === 2)) || (tab === 4 && (this.item?.postQuestionStatus === 2)) || (tab === 5 && (this.item?.imageEvidenceStatus === 2))) {
        return 'red';
      } else {
        return 'yellow';
      }
    },
    previewImage(url, isShowDialog = false) {
      if (isShowDialog) {
        this.showDialogImage = true;
        this.imageUrl = pathImageUrl + url;
      }
      return pathImageUrl + url;
    },

    downloadImage(url) {
      const urlImage = pathImageUrl + url;

      axios({
        method: 'get',
        urlImage,
        responseType: 'arraybuffer',
      })
          .then((response) => {
            this.forceFileDownload(response, 'image.png')
          })
          .catch((e) => console.log(e))


    },

    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', title)
      document.body.appendChild(link)
      link.click()
    },

    checkImageOrder(imageType) {
      return this.imageOrders.filter((e) => e.ImageType.parentID === imageType?.parentID && e.imageTypeID == imageType.id);
    },

    changeStatus(status, withCancel) {

      if (status === 2 && !withCancel) {
        this.showDialogCancelReason = true;
      } else {
        let data = {};
        if (this.tab === 3) {
          data = {preQuestionStatus: status}
        } else if (this.tab === 4) {
          data = {postQuestionStatus: status}
        } else if (this.tab === 5) {
          data = {imageEvidenceStatus: status}
        }
        if (withCancel === true) {
          if (this.tab === 3) {
            data['preQuestionCancelReason'] = this.postCancelReason;
          } else if (this.tab === 4) {
            data['postQuestionCancelReason'] = this.postCancelReason;
          } else if (this.tab === 5) {
            data['imageEvidenceCancelReason'] = this.postCancelReason;
          }
        }
        this.showDialogCancelReason = false;
        networks.fetchFromWeb(urlUpdateOrder + this.id, 2, data)
            .then(response => {

              if (response.status === 200) {
                alert("the data has been edited successfully");
                this.clear();
                this.getOrdersFromApi();
                this.cartAddons.push(this.cartAddon);
              }


            })
            .catch(error => {
              this.errors = error.response.data.errors || error.response.data.error || error.response.data;
              this.buttonLoading = false;
            });
      }

    },


    validate() {
      if (this.$refs.form[0].validate()) {
        this.buttonLoading = true;
        this.errors = [];
        this.saveItem();
      }
    },

    saveItem() {

      let answers = [];

      this.questionAnswers.forEach((value, index) => {
        if (value !== null) answers.push({questionID: index, answerID: value.id});
      });

      let data = {
        userID: this.user.id,
        installDate: this.installDate,
        installCity: this.installCity,
        installLine1: this.installLine1,
        installLine2: this.installLine2,
        installPostcode: this.installPostcode,
        city: this.city,
        addressLine1: this.addressLine1,
        addressLine2: this.addressLine2,
        postcode: this.postcode,
        addons: this.cartAddons.filter((e) => e.addon.id > 0),
        productID: this.product.id,
        productTotalPrice: this.productTotal,
        installPrice: this.installPrice,
        postcodePrice: this.postcodePrice,
        discount: this.totalDiscount,
        tax: this.totalTax,
        total: this.totalFinalPrice,
        subPostcode: this.installPostcode,
        questions: answers,
        type: this.type,
        note: this.note,
        cancelReason: this.cancelReason,
        isSameInstall: this.isSameAddress,
        status: this.itemStatusOrder.id,
      };


      if (this.isFormTitleEdit)
        networks.fetchFromWeb(urlUpdateOrder + this.id, 2, data)
            .then(response => {

              if (response.status === 200) {
                this.clear();
                this.dialogAddItem = false;
                alert("the data has been edited successfully");
                this.getOrdersFromApi();
              } else {
                this.errors = response.data.errors || response.data.error;
                this.buttonLoading = false;
              }


            })
            .catch(error => {
              this.errors = error.response.data.errors || error.response.data.error;
              this.buttonLoading = false;
            });
      else {

        networks.fetchFromWeb(urlCreateOrder, 1, data)
            .then(response => {

              if (response.status === 200) {
                this.clear();
                this.dialogAddItem = false;
                alert("the data has been added successfully");
                this.getOrdersFromApi();
              } else {
                this.errors = response.data.errors || response.data.error;
                this.buttonLoading = false;
              }


            })
            .catch(error => {
              this.errors = error.response.data.errors || error.response.data.error;
              this.buttonLoading = false;

            });
      }

    },

    clear() {
      if (this.$refs.form != undefined && this.$refs.form[0]) {
        this.$refs.form[0].resetValidation();

      }
      if (this.$refs.form != undefined && this.$refs.form[1]) {
        this.$refs.form[1].resetValidation();

      }


      this.user = null;
      this.itemStatusOrder = null;
      this.id = 0;
      this.tab = 0;

      this.type = null;
      this.planName = null;

      this.installDate = null;
      this.cartAddons = [];
      this.cartAddons.push(this.cartAddon);

      this.entriesInstallAddress = [];
      this.isLoadingInstallAddress = false;
      this.suggestionInstallAddress = null;
      this.installSearch = null;
      this.urlSelectedInstallAddress = null;
      this.selectedInstallAddress = null;

      this.entriesBillingAddress = [];
      this.isLoadingBillingAddress = false;
      this.suggestionBillingAddress = null;
      this.billingSearch = null;
      this.urlSelectedBillingAddress = null;
      this.selectedBillingAddress = null;

      this.city = null;
      this.addressLine1 = null;
      this.addressLine2 = null;
      this.postcode = null;
      this.installLine1 = null;
      this.installLine2 = null;
      this.installCity = null;
      this.installPostcode = null;
      this.isSameAddress = false;

      this.questionAnswers = [];
      this.orderType = 0;
      this.totalPrices = 0;
      this.productTotal = 0;
      this.installPrice = 0;
      this.postcodePrice = 0;
      this.totalDiscount = 0;
      this.totalTax = 0;
      this.product = null;
      this.note = null;
      this.cancelReason = null;
      this.addons = [];
      this.valid = true;
      this.errors = [];
      this.buttonLoading = false;
      this.paymentType = null;

      //// Tab form 2 index
      this.engineers = [];
      this.engineer = null;
      this.dessertsEngineers = [];
      this.buttonLoadingAssign = false;

      ////// Tab Form 3 index
      this.installAnswers = [];

      ////// Tab Form 5 index
      this.imageOrders = [];
      this.showDialogImage = false;
      this.imageUrl = null;
      this.dialogAddItem = false;

      ////// Tab Form 5 index
      this.noteOrders = [];
      this.noteAdminID = null;
      this.noteAdminBody = null;
      this.noteAdminTitle = null;

      this.postCancelReason = null;
      this.showDialogCancelReason = false;

    },

    clearEngTab() {
      this.engPrice = null;
      this.engNote = null;
      this.engIsVerticalFlue = true;
      this.engineer = null;
    },


    clickEditItem(item) {
      this.loading = true;
      networks.fetchFromWeb(urlShowOrder + item.id)
          .then(response => {
            this.editItem(response.data);
            this.loading = false;
          })
          .catch(error => {
            alert(error);
          });
    },

    editItem(item) {
      this.clear();
      this.item = item;
      this.isFormTitleEdit = true;
      this.id = item.id;
      this.user = this.getUser(item.userID);
      this.itemStatusOrder = {id: item.status};
      this.installDate = item.installDate;
      this.selectedInstallAddress = {
        line_1: item.AddressOrder?.installLine1,
        line_2: item.AddressOrder?.installLine2,
        post_town: item.AddressOrder?.installCity,
        postcode: item.AddressOrder?.installPostcode,
      };
      this.selectedBillingAddress = {
        line_1: item.AddressOrder?.addressLine1,
        line_2: item.AddressOrder?.addressLine2,
        post_town: item.AddressOrder?.city,
        postcode: item.AddressOrder?.postcode,
      };

      this.product = this.products.find((e) => e.id == item.productID);
      this.productPrice = Number(this.product?.price) - Number(this.product?.discount);
      this.productTotal = item.productTotalPrice;
      this.planName = item.Plan?.name??null;
      this.orderType = item.type;
      this.checkOrderType();
      this.note = item.note;
      this.couponPrice = item.couponPrice;
      this.couponSlug = item.couponSlug;
      this.cancelReason = item.cancelReason;
      this.isSameAddress = item.AddressOrder?.isSameInstall ?? false;
      this.installPrice = item.installPrice;
      this.postcodePrice = item.postcodePrice;
      this.getAddonsFromApi();

      this.cartAddons = [];
      item.AddonOrders.map(data => {
        this.cartAddons.push({
              addon: {id: data.addonID, price: data.price},
              number: data.amount,
              total: data.total,
            }
        );
      });
      this.calculateTotalPrices();

      item.OrderQuestions.map(data => {
        this.questionAnswers[data.questionID] = {id: data.answerID};
      });

      //// Tab form 2 index
      this.getEngineersFromApi(item);
      this.dessertsEngineers = item.AssignEngineers;

      //// Tab form 3 index
      this.installAnswers = item.InstallAnswers;

      ////// Tab Form 5 index
      this.imageOrders = item.ImageOrders;
      this.showDialogImage = false;
      this.pathUploadImages = urlUploadImages + item.uuid;

      ////// Tab Form 8 index
      this.noteOrders = item.NoteOrders;
      this.isEditNoteMode = false;


      this.dialogAddItem = true;
    },

    checkOrderType() {
      switch (this.orderType) {
        case 1:
          this.paymentType = "Pay Monthly";
          break;

        case 2:
          this.paymentType = "Pay by Card";
          break;

        case 3:
          this.paymentType = "Pay by Card 250";
          break;

        case 4:
          this.paymentType = "Pay Monthly ( Manual Finance )";
          break;

        case 5:
          this.paymentType = "Pay by Klarna";
          break;

        default:
          this.paymentType = 'Manual Payment';

      }
    },

    showDialogDelete(item) {
      if (this.selectedItems.length < 1) {
        if (!item.deletedAt || (item.deletedAt && this.isForceDeleted)) {
          this.disableChangeDelete = item.deletedAt;
          this.dialogDelete = true;
          this.deleteItemIDs.push(item.id);
        }
      } else {
        this.dialogDelete = true;
      }

    },
    hideDialogDelete() {
      this.dialogDelete = false;
      this.disableChangeDelete = false;
      this.deleteItemIDs = [];
      this.selectedItems = [];
    },
    destroyItems() {

      this.buttonLoading = true;
      if (this.selectedItems.length > 0) {
        let currentSelected = [];

        if (this.isForceDeletedRow)
          currentSelected = this.selectedItems;
        else
          currentSelected = this.selectedItems.filter((e) => e.deletedAt === false);

        currentSelected.map((e) => this.deleteItemIDs.push(e.id));
      }


      if (this.deleteItemIDs.length < 1) {
        this.hideDialogDelete();
        this.buttonLoading = false
      } else {
        networks.fetchFromWeb(urlOrders + this.deleteItemIDs + "/" + this.isForceDeletedRow, 3)
            .then(response => {

              if (response.status == 200) {
                this.hideDialogDelete();
                alert("Data has been deleted");
                this.getOrdersFromApi();
              }

            })
            .catch(() => {
              alert("Failed for process delete data");
              this.hideDialogDelete();
            }).finally(() => this.buttonLoading = false);
      }

    },

    restoreItems(item) {
      this.buttonLoading = true;
      if (this.selectedItems.length > 0) {
        let currentSelected = [];
        currentSelected = this.selectedItems.filter((e) => e.deletedAt === true);
        currentSelected.map((e) => this.deleteItemIDs.push(e.id));

      } else {
        this.deleteItemIDs.push(item.id);
      }

      if (this.deleteItemIDs.length < 1) {
        this.hideDialogDelete();
        this.buttonLoading = false;
      } else {

        networks.fetchFromWeb(urlOrders + this.deleteItemIDs + "/" + this.isForceDeletedRow + "/true", 3)
            .then(response => {

              if (response.status == 200) {
                this.hideDialogDelete();

                this.getOrdersFromApi();
              }

            })
            .catch(() => {
              alert("Failed for process delete data");
              this.hideDialogDelete();
            }).finally(() => this.buttonLoading = false);

      }
    }


  },
}
</script>

<style>
.v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: rotate(0deg) !important;
}
</style>


